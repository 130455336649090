import React from 'react'
import Layout from '../components/LayoutLanding'
import '../styles/styles.scss'

const apprenticeDownload = () => {
  return (
    <Layout>
      <div className="success">
        <h1>SUCCESS! Thank you for subscribing, Choose Your Download Below:</h1>
      </div>
    </Layout>
  )
}

export default apprenticeDownload
